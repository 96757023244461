<template>
  <div>

    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">
        {{ $t('Error fetching user data') }}
      </h4>
      <div class="alert-body">
        {{ $t('No user found with this user id. Check') }}
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          {{ $t('User List') }}
        </b-link>
        {{ $t('for other users.') }}
      </div>
    </b-alert>

    <template v-if="userData">
      <user-view-user-info-card :user-data="userData" />
      <!-- <b-col cols="12" md="5" xl="3" lg="4">
          <user-view-user-plan-card :user-data="userData" />
        </b-col> -->


      <b-row>
        <b-col cols="12" lg="8">
          <!-- <user-view-user-timeline-card :user-data="userData" /> -->
           <user-view-exams :user-data="userData"/>
        </b-col>
        <b-col cols="12" lg="4">
          <user-view-user-permissions-card :user-data="userData" />
        </b-col>
      </b-row>


    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'

import userStoreModule from '../memberStoreModule'
import UserViewUserInfoCard from './MemberViewUserInfoCard.vue'
import UserViewUserPlanCard from './MemberViewUserPlanCard.vue'
import UserViewUserTimelineCard from './MemberViewUserTimelineCard.vue'
import UserViewExams from './MemberViewExams.vue'
import UserViewUserPermissionsCard from './MemberViewUserPermissionsCard.vue'
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
    UserViewExams,
  },
//40959
  setup() {
    const userData = ref(null)
    const USER_APP_STORE_MODULE_NAME = 'app-member-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
   
    store.dispatch('app-member-edit/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data.users
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })
      
    return {
      userData
    }
  },
}
</script>

<style></style>
