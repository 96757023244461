<template>
    <b-card :title="$t(`User's Exams`)">
        <b-table ref="refExamListTable" class="position-relative" :items="exams" responsive :fields="tableColumns"
            primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="$t('No matching records found')"
            :sort-desc.sync="isSortDirDesc">
            <template #head()="data">
                <span class="text">{{ $t(data.label.toUpperCase()) }}</span>
            </template>
            <template #cell(title)="data">
                {{ JSON.parse(data.item.examInfo).title }}
            </template>
            <template #cell(passLevel)="data">
                <span>{{ data.item.passLevel.toFixed(2) }}</span>
            </template>
            <template #cell(attempt)="data">
                <span>{{ data.item.userAttemptCount }} / {{ JSON.parse(data.item.examInfo).maxAttemptCount }}</span>
            </template>
            <template #cell(actions)="data">
                <b-button size="sm" variant="outline-primary" @click="increaseMaxAttempt(data.item.id)">
                    {{ $t('Increase') }}</b-button>
            </template>

        </b-table>
    </b-card>
</template>
<script>
import {
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BButton,
} from 'bootstrap-vue'
import userStoreModule from '../memberStoreModule'
import { useToast } from 'vue-toastification/composition'
import { ref } from '@vue/composition-api'
import axios from '@axios'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BCard,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BButton,
    },
    data() {
        return {
            exams: [],
        }
    },
    created() {
        const USER_APP_STORE_MODULE_NAME = 'app-member-edit-page';
        // Register module
        if (!this.$store.hasModule(USER_APP_STORE_MODULE_NAME)) {
            this.$store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
        }
        this.fetchExams()
    },
    beforeDestroy() {
        const USER_APP_STORE_MODULE_NAME = 'app-member-edit-page';
        // Unregister module
        if (this.$store.hasModule(USER_APP_STORE_MODULE_NAME)) {
            this.$store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        }
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
    },
    methods: {

        fetchExams() {
            axios({
                method: "get",
                url: `/lms/admin/course/getUserExams/${this.userData.userguid}`,
            })
                .then((r) => {
                    this.exams = r.data.data
                })
                .catch((r) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Error`,
                            icon: "AlertOctagonIcon",
                            variant: "warning",
                            text: r.message
                        },
                    });
                })
        },
        increaseMaxAttempt(id) {
            axios({
                method: "get",
                url: `/lms/admin/course/increaseUserExamMaxAttempt/${id}`,
            })
                .then((r) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Success`,
                            icon: "CheckCircleIcon",
                            variant: "success",
                        },
                    });
                    this.fetchExams()
                })
                .catch((r) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Error`,
                            icon: "AlertOctagonIcon",
                            variant: "warning",
                            text: r.message
                        },
                    });
                })
        }
    },
    setup() {

        const isSortDirDesc = ref(true)
        const sortBy = ref('exams')
        const tableColumns = [
            { key: 'title', label: 'Exam', sortable: true },
            { key: 'passLevel', label: 'Score', sortable: true },
            { key: 'attempt', label: 'Attempt/Max', sortable: false },
            { key: 'actions', label: 'Actions', sortable: false },
        ]
        return {
            tableColumns,
            isSortDirDesc,
            sortBy,
        }
    }

}
</script>